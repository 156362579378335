const P = 'EDITOR';

export const GPX_ROUTE_COLORS = [
  '#ff3344',
  '#3344ff',
  '#33ff44',
  '#33ffff',
];

export const DRAWING_DIRECTIONS: Record<'FORWARDS' | 'BACKWARDS', 'forward' | 'backward'> = {
  FORWARDS: 'forward',
  BACKWARDS: 'backward',
};

export const EDITOR_HISTORY_LENGTH = 100;

export const EDITOR_ACTIONS = {
  SET_EDITING: `${P}-SET_EDITING`,
  CHANGE_MODE: `${P}-CHANGE_MODE`,
  SET_MODE: `${P}-SET_MODE`,
  SET_DISTANCE: `${P}-SET_DISTANCE`,
  SET_CHANGED: `${P}-SET_CHANGED`,
  SET_SPEED: `${P}-SET_SPEED`,
  SET_ROUTER_POINTS: `${P}-SET_ROUTER_POINTS`,
  SET_ACTIVE_STICKER: `${P}-SET_ACTIVE_STICKER`,
  START_EDITING: `${P}-START_EDITING`,
  STOP_EDITING: `${P}-STOP_EDITING`,
  ROUTER_CANCEL: `${P}-ROUTER_CANCEL`,
  ROUTER_SUBMIT: `${P}-ROUTER_SUBMIT`,

  CLEAR_POLY: `${P}-CLEAR_POLY`,
  CLEAR_STICKERS: `${P}-CLEAR_STICKERS`,
  CLEAR_ALL: `${P}-CLEAR_ALL`,
  CLEAR_CANCEL: `${P}-CLEAR_CANCEL`,

  SEND_SAVE_REQUEST: `${P}-SEND_SAVE_REQUEST`,
  SET_SAVE_LOADING: `${P}-SET_SAVE_LOADING`,
  RESET_SAVE_DIALOG: `${P}-RESET_SAVE_DIALOG`,
  CANCEL_SAVE: `${P}-CANCEL_SAVE`,

  SET_SAVE: `${P}-SET_SAVE`,

  SHOW_RENDERER: `${P}-SHOW_RENDERER`,
  HIDE_RENDERER: `${P}-HIDE_RENDERER`,
  SET_RENDERER: `${P}-SET_RENDERER`,
  TAKE_A_SHOT: `${P}-TAKE_A_SHOT`,
  CROP_A_SHOT: `${P}-CROP_A_SHOT`,

  SET_DIALOG: `${P}-SET_DIALOG`,
  SET_DIALOG_ACTIVE: `${P}-SET_DIALOG_ACTIVE`,
  LOCATION_CHANGED: `${P}-LOCATION_CHANGED`,
  SET_READY: `${P}-SET_READY`,

  SET_MARKERS_SHOWN: `${P}-SET_MARKERS_SHOWN`,
  GET_GPX_TRACK: `${P}-GET_GPX_TRACK`,
  SET_IS_EMPTY: `${P}-SET_IS_EMPTY`,
  SET_FEATURE: `${P}-SET_FEATURE`,
  SET_IS_ROUTING: `${P}-SET_IS_ROUTING`,
  KEY_PRESSED: `${P}-KEY_PRESSED`,

  SET_ROUTER: `${P}-SET_ROUTER`,
  SET_NOMINATIM: `${P}-SET_NOMINATIM`,
  SEARCH_NOMINATIM: `${P}-SEARCH_NOMINATIM`,

  SET_HISTORY: `${P}-SET_HISTORY`,
  UNDO: `${P}-UNDO`,
  REDO: `${P}-REDO`,
  CAPTURE_HIPSTORY: `${P}-CAPTURE_HIPSTORY`,

  SET_DIRECTION: `${P}-SET_DIRECTION`,
  SET_GPX: `${P}-SET_GPX`,
  UPLOAD_GPX: `${P}-UPLOAD_GPX`,
  DROP_GPX: `${P}-DROP_GPX`,
};
