export const USER_ACTIONS = {
  SET_USER: 'SET_USER',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_LOGIN: 'USER_LOGIN',
  GOT_VK_USER: 'GOT_VK_USER',

  IFRAME_LOGIN_VK: 'IFRAME_LOGIN_VK',
  SET_USER_LOCATION: 'SET_USER_LOCATION',
  
  SEARCH_SET_TITLE: 'SEARCH_SET_TITLE',
  SEARCH_SET_DISTANCE: 'SEARCH_SET_DISTANCE',
  SEARCH_CHANGE_DISTANCE: 'SEARCH_CHANGE_DISTANCE',

  SEARCH_SET_TAB: 'SEARCH_SET_TAB',
  SEARCH_PUT_ROUTES: 'SEARCH_PUT_ROUTES',
  SEARCH_SET_LOADING: 'SEARCH_SET_LOADING',

  OPEN_MAP_DIALOG: 'OPEN_MAP_DIALOG',
  MAPS_LOAD_MORE: 'MAPS_LOAD_MORE',
  MAPS_SET_SHIFT: 'MAPS_SET_SHIFT',

  DROP_ROUTE: 'DROP_ROUTE',
  SET_STARRED: 'SET_STARRED',
  MODIFY_ROUTE: 'MODIFY_ROUTE',

  SET_ROUTE_STARRED: 'SET_ROUTE_STARRED',
  TOGGLE_ROUTE_STARRED: 'TOGGLE_ROUTE_STARRED',
};
