
export const MODES = {
  POLY: 'POLY',
  STICKERS: 'STICKERS',
  STICKERS_SELECT: 'STICKERS_SELECT',
  ROUTER: 'ROUTER',
  SHOTTER: 'SHOTTER',
  TRASH: 'TRASH',
  NONE: 'NONE',
  LOGO: 'LOGO',
  SAVE: 'SAVE',
  CONFIRM_CANCEL: 'CONFIRM_CANCEL',
  PROVIDER: 'PROVIDER',
  SHOT_PREFETCH: 'SHOT_PREFETCH',
  GPX: 'GPX',
};
