const P = 'MAP'

export const MAP_ACTIONS = {
  SET_MAP: `${P}-SET_MAP`,
  SET_PROVIDER: `${P}-SET_PROVIDER`,
  SET_ROUTE: `${P}-SET_ROUTE`,
  SET_TITLE: `${P}-SET_TILE`,
  SET_DESCRIPTION: `${P}-SETDESCRIPTION`,
  SET_ADDRESS: `${P}-SET_ADDRESS`,
  SET_ADDRESS_ORIGIN: `${P}-SET_ADDRESS_ORIGIN`,
  SET_OWNER: `${P}-SET_OWNER`,
  SET_PUBLIC: `${P}-SET_PUBLIC`,
  SET_LOGO: `${P}-SET_LOGO`,

  ADD_STICKER: `${P}-ADD_STICKER`,
  SET_STICKER: `${P}-SET_STICKER`,
  SET_STICKERS: `${P}-SET_STICKERS`,
  DROP_STICKER: `${P}-DROP_STICKER`,

  MAP_CLICKED: `${P}-MAP_CLICKED`,
  ZOOM_CHANGE: `${P}-ZOOM_CHANGE`
}